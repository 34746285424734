import React from "react"
import { Box, Flex, Card, Button, Grid } from "theme-ui"

import useLanguage from "../hooks/useLanguage"
import ProductCard from "./ProductCard"

const tabStyle = {
  border: 0,
  fontSize: "14px",
  color: "text",
  transition: "0.4s",
  padding: "10px 5px",
  background: "transparent",
  flex: 1,
  fontWeight: 600,
  minWidth: 150,
  borderRadius: 0,
  borderBottom: "2px solid transparent",
}

const Products = ({ data }) => {
  const isEn = useLanguage() === "en"
  const { main_cat, products, sub_cat } = data

  const [selected, setSelected] = React.useState("")
  const [subSelected, setSubSelected] = React.useState("")

  const sortedMainCats = main_cat?.nodes?.sort(
    (a, b) => a.frontmatter.order - b.frontmatter.order
  )

  const subList =
    sub_cat?.nodes.filter(
      ({ frontmatter }) => frontmatter.main_cat === selected
    ) || []
  const productsList =
    products?.nodes.filter(
      ({ frontmatter }) => frontmatter.sub_cat === subSelected
    ) || []

  function handleChangeMain(val) {
    const sub =
      sub_cat?.nodes.find(({ frontmatter }) => frontmatter.main_cat === val) ||
      []
    setSelected(val)
    setSubSelected(sub?.frontmatter?.name)
  }

  React.useEffect(() => {
    if (sortedMainCats.length !== 0) {
      const main_text = sortedMainCats[0].frontmatter.name
      const sub =
        sub_cat?.nodes.find(
          ({ frontmatter }) => frontmatter.main_cat === main_text
        ) || []

      setSelected(main_text)
      setSubSelected(sub?.frontmatter?.name)
    }
  }, [sortedMainCats.length])

  return (
    <Card
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        color: "text",
      }}
    >
      <Box sx={{ overflowX: "auto" }}>
        <Flex sx={{ justifyContent: "spaceBetween" }}>
          {sortedMainCats.map(({ frontmatter }, i) => (
            <Button
              key={`cat_${i}`}
              sx={{
                ...tabStyle,
                ...(selected === frontmatter.name && {
                  color: "primary",
                  borderBottom: "2px solid;",
                }),
              }}
              onClick={() => handleChangeMain(frontmatter.name)}
            >
              {isEn ? frontmatter.name : frontmatter.name_ar}
            </Button>
          ))}
        </Flex>
      </Box>
      <Box px={3} sx={{ minHeight: ["auto", null, null, null, null, 400] }}>
        <Flex my={2}>
          {subList.map(({ frontmatter }, i) => (
            <Button
              key={`sub_cat_${i}`}
              variant="white"
              sx={{
                fontSize: 13,
                bg: subSelected === frontmatter.name && "primary",
                color: subSelected === frontmatter.name && "white",
                p: "3px 20px",
                marginInlineEnd: 5,
                ":hover": {
                  color: subSelected === frontmatter.name && "white",
                },
              }}
              onClick={() => setSubSelected(frontmatter.name)}
            >
              {isEn ? frontmatter.name : frontmatter.name_ar}
            </Button>
          ))}
        </Flex>
        {subList.length !== 0 && (
          <Box
            sx={{
              borderTop: t => `1px solid ${t.colors.primaryLight}`,
              mt: "10px",
              pt: "20px",
            }}
          >
            <Grid columns={[1, 1, 1, 2, 3, 4]} gap={3}>
              {productsList.map(({ frontmatter }, i) => (
                <ProductCard key={i} frontmatter={frontmatter} />
              ))}
            </Grid>
          </Box>
        )}
      </Box>
    </Card>
  )
}

Products.propTypes = {}

export default Products
