import React from "react"
import { Box, Card, Heading, Text } from "theme-ui"
import Img from "gatsby-image"
import Zoom from "react-reveal/Zoom"
import useLanguage from "../hooks/useLanguage"

const ProductCard = ({ frontmatter }) => {
  const isEn = useLanguage() === "en"
  return (
    <Zoom>
      <Card
        sx={{
          borderRadius: 6,
          overflow: "hidden",
          height: "250px",
          position: "relative",
          boxShadow: "0 0px 6px 0px #33333350",
          ":hover .product-content": {
            opacity: 0,
            visibility: "hidden",
          },
        }}
      >
        <Img
          fluid={frontmatter.img.childImageSharp.fluid}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
        <Box
          p={2}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#fdfdfdba",
            opacity: 1,
            visibility: "visible",
            top: 0,
            transition: "0.4s",
          }}
          className="product-content"
        >
          <Heading as="h2" mb={1}>
            {isEn ? frontmatter.title : frontmatter.title_ar}
          </Heading>
          <Text
            sx={{
              fontSize: 14,
              fontWeight: 600,
              textAlign: "justify",
            }}
          >
            {isEn ? frontmatter.desc : frontmatter.desc_ar}
          </Text>
        </Box>
      </Card>
    </Zoom>
  )
}

export default ProductCard
