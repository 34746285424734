import React from "react"
import { graphql } from "gatsby"
import { Box, Heading, Text } from "theme-ui"
import Reveal from "react-reveal/Fade"
import icon from "../images/bg.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"
import Products from "../components/products"
import useLanguage from "../hooks/useLanguage"

export default function ProductsLayout({ data }) {
  const { frontmatter } = data.markdownRemark
  const isEn = useLanguage() === "en"
  return (
    <Layout>
      <SEO title={frontmatter?.title} />
      <Box
        sx={{
          minHeight: "100vh",
          bg: "secondary",
          pt: 100,
          backgroundImage: `url(${icon})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          ...(isEn && { transform: "rotateY(180deg)" }),
        }}
      >
        <Wrapper style={{ ...(isEn && { transform: "rotateY(180deg)" }) }}>
          <Box
            sx={{
              backgroundColor: "secondaryTransparent",
              p: 3,
              color: "white",
              textAlign: "center",
              mb: 40,
            }}
          >
            <Reveal top>
              <Heading as="h1" mb={3}>
                {frontmatter.title}
              </Heading>
            </Reveal>
            <Reveal bottom>
              <Text>{frontmatter.description}</Text>
            </Reveal>
          </Box>
          <Products data={data} />
        </Wrapper>
      </Box>
    </Layout>
  )
}

export const productsQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/products/" }
    ) {
      frontmatter {
        lang
        title
        description
      }
      html
    }

    main_cat: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/categories/" } }
    ) {
      nodes {
        frontmatter {
          name
          name_ar
          order
        }
      }
    }

    sub_cat: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/subCategories/" } }
    ) {
      nodes {
        frontmatter {
          name
          name_ar
          main_cat
        }
      }
    }

    products: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/Products/" } }
    ) {
      nodes {
        frontmatter {
          title
          title_ar
          desc
          desc_ar
          sub_cat
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
